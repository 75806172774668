var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProductForm", {
    attrs: {
      value: _vm.product,
      modSets: _vm.modSets,
      products: _vm.allProducts,
      productCategories: _vm.productCategories,
      integrated: _vm.integrated,
    },
    on: { productUpdated: _vm._handleProductUpdate },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }