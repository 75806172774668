import { ItemType } from 'components/explorer_new/itemType';

const mapFolder = (folder, actionBindings) => {
    const getSubCategoryChildren = (id) => folder.children
        .filter(x => x.inventoryTitles[0].inventorySubTitles[0] &&
            x.inventoryTitles[0].inventorySubTitles[0].inventorySubTitleID === id
        );

    var result = {
        type: ItemType.Folder,
        id: folder.inventoryTitleID,
        actionBindings: actionBindings[ItemType.Folder] || {},
        value: folder,
        title: folder.title,
        children: {
            [ItemType.Subcategory]: folder.inventorySubTitles && folder.inventorySubTitles.map(x => mapSubCategory(x, actionBindings, getSubCategoryChildren(x.inventorySubTitleID))) || [],
            [ItemType.Item]: folder
                .children
                .filter(x => x.inventoryTitles[0].inventorySubTitles.length === 0)
                .map(x => mapItem(x, actionBindings)),
        }
    };
    result.children[ItemType.Subcategory].sort((x,y) => x.order - y.order)
    return result
};

const mapSubCategory = (subCategory, actionBindings, children) => {
    return {
        type: ItemType.Subcategory,
        actionBindings: actionBindings[ItemType.Subcategory] || {},
        value: subCategory,
        title: subCategory.inventorySubTitleDescription,
        children: {
            [ItemType.Item]: children && children.map(x => mapItem(x, actionBindings)) || [],
        },
        order: parseInt(subCategory.inventorySubTitleOrder),
        id: subCategory.inventorySubTitleID,
    };
};

const mapItem = (item, actionBindings) => {
    return {
        type: ItemType.Item,
        actionBindings: actionBindings[ItemType.Item] || {},
        value: item,
        title: item.inventoryItemName,
        id: item.inventoryItemID,
        status: item.inventoryItemAvailable ? '' : 'disabled'
    };
};

export const convertStoreProductsToExplorerItems = (storeProducts, actionBindings) => {
    return {
        [ItemType.Folder]: storeProducts
            .filter(x => x.inventoryTitleDescription !== "SHOW ALL")
            .map(x => mapFolder(x, actionBindings)),
    };
};

export const hasNonEmptyChildren = (node) => {
    return node.children[ItemType.Subcategory] && node.children[ItemType.Subcategory].length > 0
        || node.children[ItemType.Item] && node.children[ItemType.Item].length > 0;
}
