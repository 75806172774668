// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("img/disabled.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".folder__item[data-v-e33e957c] {\n  font-size: 14px;\n  cursor: pointer;\n}\n.folder__item--editing[data-v-e33e957c], .folder__item--no-action[data-v-e33e957c] {\n  cursor: default;\n}\n.folder__item-status[data-v-e33e957c] {\n  width: 10px;\n  margin-left: 10px;\n  border-radius: 50%;\n}\n.folder__item-status.disabled[data-v-e33e957c], .folder__item-status.active[data-v-e33e957c] {\n  height: 10px;\n}\n.folder__item-status.disabled[data-v-e33e957c] {\n  background-color: #ff1744;\n}\n.folder__item-status.active[data-v-e33e957c] {\n  background-color: #93BD20;\n}\n.folder__item-remove[data-v-e33e957c] {\n  cursor: pointer;\n  margin-right: 10px;\n}\n.folder__item-handle[data-v-e33e957c] {\n  display: flex;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  max-width: 40px;\n}\n.folder__item-handle--active[data-v-e33e957c] {\n  cursor: grab;\n}\n.folder__item-handle--active[data-v-e33e957c]:active {\n  cursor: grabbing;\n}\n.line-item--pending-delete[data-v-e33e957c] {\n  color: #a7a7a7;\n}\n.line-item--pending-delete .folder__item__title[data-v-e33e957c] {\n  position: relative;\n}\n.line-item--pending-delete .folder__item__title[data-v-e33e957c]:before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  top: 50%;\n  left: 0;\n  transform: translate(-24px, -50%);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 12px 12px;\n  background-position: center;\n  width: 12px;\n  height: 12px;\n}\n@media (max-width: 767px) {\n.data-col[data-v-e33e957c] {\n    font-size: 10px;\n}\n}", ""]);
// Exports
module.exports = exports;
