<template>
    <div
        class="folder"
        :class="{ 'folder--active': expanded, 'folder--edit': editMode }"
    >
        <line-item class="height-xs--7">
            <div class="folder__line row no-gutters height-xs--inherit align-items-center" @click="toggleExpanded">
                <div class="folder__head col">
                    <div v-if='item.actionBindings.onAddSubcategory' class="folder__icon" :class="{'folder__icon--active': !expanded}"></div>
                    <div v-else class="folder__icon" :class="{'folder__icon--active': !expanded}"></div>
                    <div class="folder__title">{{ item.title }}</div>

                    <template v-if='editMode'>
                        <div
                            class="drag-handle folder__sort icon icon--handle folder__action"
                            @click.stop.prevent
                        />

                        <div
                            v-if='item.actionBindings.onClick'
                            class="folder__edit folder__action"
                            @click.stop="item.actionBindings.onClick(item)"
                        />

                        <div
                            v-if='item.actionBindings.onDelete'
                            class="folder__delete folder__action"
                            @click.stop="item.actionBindings.onDelete(item)"
                        />

                        <div
                            v-if='item.actionBindings.onAddSubcategory'
                            class="folder__add_subcategory folder__action"
                            @click.stop='item.actionBindings.onAddSubcategory(item)'
                        />
                    </template>
                </div>

                <div class="folder__struct col" v-if="expanded">
                    <div class="row no-gutters">
                        <template v-for="(prop, key) in struct">
                            <div class="col text--capped" v-if="typeof prop === 'string' || prop.display" :key="key">
                                <template v-if="typeof prop === 'string'">
                                    {{ prop }}
                                </template>
                                <template v-else>
                                    {{ prop.display }}
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </line-item>

        <div class="folder__children row no-gutters" v-if='expanded'>
            <div class='folder__children--inner col-12'>
                <list :items='item.children' :parent='item'/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import lineItem from 'components/line_item';
import { ItemType } from 'components/explorer_new/itemType';

export default {
    name: 'folder',
    props: {
        item: Object
    },
    components: {
        list: () => import('components/explorer_new/list'),
        lineItem,
    },
    computed: {
        ...mapGetters("ExplorerStore", ["struct", "editMode", "isSelected", "isExpanded"]),
        expanded() {
            return this.isExpanded(ItemType.Folder, this.item.id);
        }
    },
    methods: {
        ...mapMutations("ExplorerStore", ["SET_EXPANDED"]),
        toggleExpanded() {
            this.SET_EXPANDED({
                type: ItemType.Folder,
                id: this.item.id,
                isExpanded: !this.expanded
            });
        }
    }
};
</script>

<style lang='scss'>
@import '~scss/variables';
@import '~scss/mixins';

.folder {
    &__line {
        background-color: #fff;
        cursor: pointer;
    }

    &__action {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: spacing(xs);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    }

    &__sort {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__edit {
        background-image: url('~img/edit_logo.svg');
    }

    &__delete {
        background-image: url('~img/delete.svg');
        background-size: 15px;
    }

    &__add_subcategory {
        background-image: url('~img/subfolder_add.svg');
        background-size: 20px;
        width: 19px;
        height: 11px;
        margin-left: 6px;
    }

    &__head {
        display: flex;
        font-size: fz(sm);
    }

    &__icon {
        @include background-image('', 'folder_closed_logo.svg', '../../img/');
        background-size: contain;
        width: spacing(sm);
        height: spacing(sm);
        margin-top: 2px;
        margin-left: spacing(sm);

        &--active {
            @include background-image('', 'folder_open_logo.svg', '../../img/');
        }
    }



    &__title {
        margin-left: spacing(xs);
    }

    &__children {
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;
        &--inner {
            min-height: 10px;
        }
    }

    &__child {
        transition: $transition_short;

        &--ghost {
            border-left: 5px solid $brand_color;
        }
    }

    &--active {
        .folder__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    &--edit {
        @include below(sm) {
            .folder__struct {
                display: none;
            }
        }
    }
}
</style>
