<template>
    <line-item
        :class="{'line-item--active': active, 'line-item--pending-delete': pendingDelete}"
        :dark="true"
        :id="item.id"
    >
        <div
            class="row no-gutters align-items-center justify-content-between height-xs--9 folder__item"
            :class="{'folder__item--editing': editMode, 'folder__item--editing': !item.actionBindings.onClick}"
            @click="item.actionBindings.onClick ? item.actionBindings.onClick(item) : true"
        >
            <div :class="['folder__item-status', item.status]" :title="resolveItemTooltip(item.status)"></div>

            <div
                class="folder__item-handle col-1"
                :class="{'folder__item-handle--active': editMode}"
            >
                <div v-if="editMode" class="drag-handle icon icon--handle"></div>
            </div>

            <div
                v-if="item.actionBindings.onDelete"
                class="folder__item-remove icon icon--remove"
                @click="item.actionBindings.onDelete(item)"
            ></div>

            <div class="col folder__item__title">
                <div v-if="struct && struct.title">{{ struct.title.value(item.value) }}</div>
            </div>

            <div class="col-6" v-if="struct">
                <div class="row no-gutters align-items-center">
                    <div class="data-col col" v-for="(prop, key) in struct" v-if="key !== 'title'" :key="key">
                        <template v-if="typeof prop === 'string'">{{ item.value[key] }}</template>
                        <template v-else>{{ prop.value(item.value) }}</template>
                    </div>
                </div>
            </div>
        </div>
    </line-item>
</template>

<script>
import { mapGetters } from 'vuex';
import { ItemType } from 'components/explorer_new/itemType';
import lineItem from 'components/line_item';

export default {
    name: 'item',
    props: {
        item: Object
    },
    components: {
        lineItem,
    },
    methods: {
        resolveItemTooltip(status) {
            if (status === "disabled") {
                return "Disabled";
            }

            return "Active";
        }
    },
    computed: {
        ...mapGetters("ExplorerStore", ["struct", "editMode", "isSelected", "isPendingDelete"]),
        active() {
            return this.isSelected(ItemType.Item, this.item.id);
        },
        pendingDelete() {
            return this.isPendingDelete(ItemType.Item, this.item.id);
        }
    }

};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.folder__item {
    font-size: fz(sm);
    cursor: pointer;

    &--editing,
    &--no-action {
        cursor: default;
    }

    &-status {
        width: 10px;
        margin-left: 10px;
        border-radius: 50%;

        &.disabled,
        &.active {
            height: 10px;
        }

        &.disabled {
            background-color: $error_red;
        }

        &.active {
            background-color: $brand_color;
        }
    }

    &-remove {
        cursor: pointer;
        margin-right: 10px;
    }

    &-handle {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        max-width: 40px;

        &--active {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }
    }
}

.line-item {
    &--pending-delete {
        color: $grey_mid_1;
        .folder__item__title {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(-24px, -50%);
                background-image: url("~img/disabled.svg");
                background-repeat: no-repeat;
                background-size: 12px 12px;
                background-position: center;
                width: 12px;
                height: 12px;
            }
        }
    }
}

.data-col {
    @include below(sm) {
        font-size: 10px;
    }
}
</style>
