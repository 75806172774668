var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.type === _vm.ItemType.Item
    ? _c("item", _vm._b({}, "item", _vm.commonProps, false))
    : _vm.item.type === _vm.ItemType.Folder
    ? _c("folder", _vm._b({}, "folder", _vm.commonProps, false))
    : _vm.item.type === _vm.ItemType.Subcategory
    ? _c("subcategory", _vm._b({}, "subcategory", _vm.commonProps, false))
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }