var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("line-item", { staticClass: "height-xs--7" }, [
        _c(
          "div",
          {
            staticClass:
              "subcategory__line row no-gutters height-xs--inherit align-items-center",
            on: { click: _vm.toggleExpanded },
          },
          [
            _c(
              "div",
              { staticClass: "subcategory__head col" },
              [
                _c("div", {
                  staticClass: "subcategory__icon",
                  class: { "subcategory__icon--active": !_vm.expanded },
                }),
                _c("div", { staticClass: "subcategory__title" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
                _vm.editMode
                  ? [
                      _c("div", {
                        staticClass:
                          "drag-handle subcategory__sort icon icon--handle subcategory__action",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm.item.actionBindings.onClick
                        ? _c("div", {
                            staticClass:
                              "subcategory__edit subcategory__action",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.item.actionBindings.onClick(_vm.item)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.item.actionBindings.onDelete
                        ? _c("div", {
                            staticClass:
                              "subcategory__delete subcategory__action",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.item.actionBindings.onDelete(
                                  _vm.item
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm.expanded
        ? _c(
            "div",
            { staticStyle: { "margin-left": "30px", "padding-right": "30px" } },
            [
              _c("list", {
                attrs: { items: _vm.item.children, parent: _vm.item },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }