var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "folder",
      class: { "folder--active": _vm.expanded, "folder--edit": _vm.editMode },
    },
    [
      _c("line-item", { staticClass: "height-xs--7" }, [
        _c(
          "div",
          {
            staticClass:
              "folder__line row no-gutters height-xs--inherit align-items-center",
            on: { click: _vm.toggleExpanded },
          },
          [
            _c(
              "div",
              { staticClass: "folder__head col" },
              [
                _vm.item.actionBindings.onAddSubcategory
                  ? _c("div", {
                      staticClass: "folder__icon",
                      class: { "folder__icon--active": !_vm.expanded },
                    })
                  : _c("div", {
                      staticClass: "folder__icon",
                      class: { "folder__icon--active": !_vm.expanded },
                    }),
                _c("div", { staticClass: "folder__title" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
                _vm.editMode
                  ? [
                      _c("div", {
                        staticClass:
                          "drag-handle folder__sort icon icon--handle folder__action",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                          },
                        },
                      }),
                      _vm.item.actionBindings.onClick
                        ? _c("div", {
                            staticClass: "folder__edit folder__action",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.item.actionBindings.onClick(_vm.item)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.item.actionBindings.onDelete
                        ? _c("div", {
                            staticClass: "folder__delete folder__action",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.item.actionBindings.onDelete(
                                  _vm.item
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.item.actionBindings.onAddSubcategory
                        ? _c("div", {
                            staticClass:
                              "folder__add_subcategory folder__action",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.item.actionBindings.onAddSubcategory(
                                  _vm.item
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm.expanded
              ? _c("div", { staticClass: "folder__struct col" }, [
                  _c(
                    "div",
                    { staticClass: "row no-gutters" },
                    [
                      _vm._l(_vm.struct, function (prop, key) {
                        return [
                          typeof prop === "string" || prop.display
                            ? _c(
                                "div",
                                { key: key, staticClass: "col text--capped" },
                                [
                                  typeof prop === "string"
                                    ? [_vm._v(" " + _vm._s(prop) + " ")]
                                    : [
                                        _vm._v(
                                          " " + _vm._s(prop.display) + " "
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm.expanded
        ? _c("div", { staticClass: "folder__children row no-gutters" }, [
            _c(
              "div",
              { staticClass: "folder__children--inner col-12" },
              [
                _c("list", {
                  attrs: { items: _vm.item.children, parent: _vm.item },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }