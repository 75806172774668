<template>
    <item
        v-if='item.type === ItemType.Item'
        v-bind='commonProps'
    />
    <folder
        v-else-if='item.type === ItemType.Folder'
        v-bind='commonProps'
    />
    <subcategory
        v-else-if='item.type === ItemType.Subcategory'
        v-bind='commonProps'
    />
</template>

<script>
import item from './item';
import folder from './folder';
import subcategory from './sub_category';
import { ItemType } from 'components/explorer_new/itemType';

export default {
    name: 'item_wrapper',
    components: {
        item, folder, subcategory
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        activeItems: Array,
        pendingDelete: Array,
        editMode: Boolean,
        struct: Object,
        mode: String,
        onDragStart: Function,
        onDragEnd: Function,
        orderable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        commonProps() {
            return {
                item: this.item,
                activeItems: this.activeItems,
                pendingDelete: this.pendingDelete,
                editMode: this.editMode,
                struct: this.struct,
                mode: this.mode,
                onDragStart: this.onDragStart,
                onDragEnd: this.onDragEnd,
                orderable: this.orderable,
            }
        },
        ItemType: () => ItemType,
    }
};
</script>

<style scoped>

</style>
