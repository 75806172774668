// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("img/edit_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../img/subfolder_open.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../img/subfolder_closed.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".subcategory__line {\n  background-color: #fff;\n  cursor: pointer;\n}\n.subcategory__action {\n  display: inline-block;\n  width: 12px;\n  height: 12px;\n  margin-top: 4px;\n  margin-left: 12px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: 12px;\n}\n.subcategory__sort {\n  cursor: grab;\n}\n.subcategory__sort:active {\n  cursor: grabbing;\n}\n.subcategory__edit {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.subcategory__delete {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 15px;\n}\n.subcategory__head {\n  margin-left: 30px;\n  display: flex;\n  font-size: 14px;\n}\n.subcategory__icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 24px;\n  height: 24px;\n  margin-left: 18px;\n}\n.subcategory__icon--active {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-repeat: no-repeat;\n}\n.subcategory__title {\n  margin-left: 12px;\n  margin-top: 3px;\n}\n.subcategory__children {\n  transition: max-height 100ms;\n  background-color: #fff;\n  overflow: hidden;\n}\n.subcategory__children--inner {\n  min-height: 10px;\n}\n.subcategory__child {\n  transition: 200ms;\n}\n.subcategory__child--ghost {\n  border-left: 5px solid #93BD20;\n}\n@media (max-width: 575px) {\n.subcategory--active .subcategory__title {\n    max-width: 115px;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n}", ""]);
// Exports
module.exports = exports;
