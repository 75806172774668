// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("img/edit_logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("img/subfolder_add.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../img/folder_closed_logo.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../img/folder_open_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".folder__line {\n  background-color: #fff;\n  cursor: pointer;\n}\n.folder__action {\n  display: inline-block;\n  width: 12px;\n  height: 12px;\n  margin-top: 4px;\n  margin-left: 12px;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: 12px;\n}\n.folder__sort {\n  cursor: grab;\n}\n.folder__sort:active {\n  cursor: grabbing;\n}\n.folder__edit {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.folder__delete {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 15px;\n}\n.folder__add_subcategory {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: 20px;\n  width: 19px;\n  height: 11px;\n  margin-left: 6px;\n}\n.folder__head {\n  display: flex;\n  font-size: 14px;\n}\n.folder__icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 18px;\n  height: 18px;\n  margin-top: 2px;\n  margin-left: 18px;\n}\n.folder__icon--active {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-repeat: no-repeat;\n}\n.folder__title {\n  margin-left: 12px;\n}\n.folder__children {\n  transition: max-height 100ms;\n  background-color: #fff;\n  overflow: hidden;\n}\n.folder__children--inner {\n  min-height: 10px;\n}\n.folder__child {\n  transition: 200ms;\n}\n.folder__child--ghost {\n  border-left: 5px solid #93BD20;\n}\n@media (max-width: 575px) {\n.folder--active .folder__title {\n    max-width: 115px;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n}\n@media (max-width: 767px) {\n.folder--edit .folder__struct {\n    display: none;\n}\n}", ""]);
// Exports
module.exports = exports;
