<template>
    <div>
        <draggable
            v-for='x in groups'
            :list="items[x]"
            :options="{
                    handle: ['.drag-handle'],
                    animation: 150,
                    group: x,
                }"
            @change='onDragEnd'
            @start='onDragStart(x)'
            :empty-insert-threshold="50"
            :swapThreshold='10'
        >

            <div v-if='!items[x] || items[x].length === 0' :class='{"list__placeholder": true, "list__placeholder-active": isDragInProgress === x}'>
                Drop {{x}} here
            </div>
            <template v-else>
                <div v-for="(item, key) in items[x]" :key="key">
                    <item-wrapper :item='item'/>
                </div>
            </template>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import itemWrapper from './item_wrapper';
import { ItemType } from 'components/explorer_new/itemType';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'list',
    props: {
        items: Object,
        parent: Object,
    },
    computed: {
        ...mapGetters("ExplorerStore", ["isDragInProgress"]),
        parentType() {
            if (!this.parent) {
                return "root";
            }

            return this.parent.type;
        },
        groups(){
            switch (this.parentType) {
            case "root":
                return [ItemType.Folder];
            case ItemType.Folder:
                return [ItemType.Subcategory, ItemType.Item];
            case ItemType.Subcategory:
                return [ItemType.Item];
            default:
                return [];
            }
        },
    },
    components: {
        draggable,
        itemWrapper
    },
    methods: {
        ...mapActions("ExplorerStore", ["handleDragEnd", "handleDragStart"]),
        onDragEnd() {
            this.handleDragEnd({
                parent: this.parent,
            });
        },
        onDragStart(type) {
            this.handleDragStart({
                type,
            });
        }
    },
};
</script>

<style lang='scss'>
.list {
    &__placeholder {
        display: none;
        min-height: 30px;
        width: 100%;
        border: 1px #93BD20 dashed;
        margin-top: 1px;

        &-active {
            display: block;
        }
    }
}
</style>
