var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.productCategories.length
    ? _c(
        "div",
        [
          _c("context-title", {
            attrs: {
              passedActions: _vm.titleBarActions,
              spaceBetween: false,
              title: "Products",
            },
          }),
          _vm.editMode ? _c("span", { staticClass: "edit-bar" }) : _vm._e(),
          _vm.canEdit
            ? _c(
                "iexplorer-header",
                {
                  attrs: { logo: "add" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.enableCategoryModal("new")
                    },
                  },
                },
                [_vm._v("Add new category")]
              )
            : _vm._e(),
          _c("list", { attrs: { items: _vm.listItems } }),
          _vm.modalActive
            ? _c(
                "editing-modal",
                [
                  _c("editing-modal-header", {
                    attrs: {
                      items: _vm.modalItems,
                      cancelAction: _vm.reset,
                      saveAction: _vm.handleModalSave,
                      displayProp: "inventoryItemName",
                    },
                  }),
                  _c("div", { staticClass: "modal-message" }, [
                    _vm._v(
                      " None of the following fields will update the selected products unless the fields are explicitly changed. "
                    ),
                  ]),
                  _vm.modalItems.length
                    ? _c("multi-product-form", {
                        attrs: {
                          value: _vm.modalItems,
                          modSets: _vm.modSets,
                          allProducts: _vm.products,
                          productCategories: _vm.productCategories,
                          store: _vm.activeStore,
                          integrated: _vm.integrated,
                        },
                        on: {
                          input: function ($event) {
                            _vm.editedModalItems = arguments[0]
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.categoryModalType
            ? _c(
                "editing-modal",
                { attrs: { size: "sm" } },
                [
                  _vm.categoryModalType === "new"
                    ? [
                        _c(
                          "alert-dialog",
                          {
                            staticClass: "category-modal",
                            attrs: { actions: _vm.addCategoryActions },
                          },
                          [
                            _c("form-input", {
                              staticClass: "category-modal__input",
                              attrs: {
                                bare: true,
                                placeholder: "New category name",
                                autofocus: "",
                              },
                              model: {
                                value: _vm.newCategoryName,
                                callback: function ($$v) {
                                  _vm.newCategoryName = $$v
                                },
                                expression: "newCategoryName",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "alert-dialog",
                          {
                            staticClass: "category-modal",
                            attrs: { actions: _vm.editCategoryActions },
                          },
                          [
                            _c("form-input", {
                              staticClass: "category-modal__input",
                              attrs: {
                                bare: true,
                                placeholder: "Category name",
                                autofocus: "",
                              },
                              model: {
                                value: _vm.newCategoryName,
                                callback: function ($$v) {
                                  _vm.newCategoryName = $$v
                                },
                                expression: "newCategoryName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "640" },
              model: {
                value: _vm.subcategoryModal.isVisible,
                callback: function ($$v) {
                  _vm.$set(_vm.subcategoryModal, "isVisible", $$v)
                },
                expression: "subcategoryModal.isVisible",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(" " + _vm._s(this.subcategoryModal.title) + " "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Subcategory name" },
                        model: {
                          value: _vm.subcategoryModal.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.subcategoryModal, "name", $$v)
                          },
                          expression: "subcategoryModal.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#93BD20", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.saveSubcategory()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "#93BD20", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.subcategoryModal.isVisible = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }