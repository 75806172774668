<template>
    <ProductForm
        :value="product"
        :modSets="modSets"
        :products="allProducts"
        :productCategories="productCategories"
        :integrated="integrated"
        @productUpdated="_handleProductUpdate"
    />
</template>

<script>
import clone from 'clone'
import { updatedDiff } from 'deep-object-diff'
import { removeDups } from 'helpers'
import ProductForm from 'components/product_form.vue'

const arrayPropDef = {
    type: Array,
    required: true,
}

export default {
    name: 'MultiProductForm',

    components: {
        ProductForm,
    },

    props: {
        value: arrayPropDef,
        allProducts: arrayPropDef,
        modSets: arrayPropDef,
        productCategories: arrayPropDef,
        store: {
            type: Object,
            required: true,
        },
        integrated: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        const baseProduct = {
            inventoryItemAvailable: true,
            taxes: [
                {
                    Amount: 0,
                    Description: 'State',
                },
                {
                    Amount: 0,
                    Description: 'Local',
                },
                {
                    Amount: 0,
                    Description: 'Other',
                },
            ],
            taxRate: this.store.taxRate,
            modSets: [],
            modGroups: [],
            inventoryMainOptionChoice: {
                Choices: [],
                Options: [],
            }, 
            bAlcohol: false,
            inventoryTitles: [''],
            inventoryTags: [],
            startTimeLocal: '',
            endTimeLocal: '',
        }

        return {
            baseProduct,
            products: this.value,
            product: clone(baseProduct),
        }
    },

    methods: {
        _handleProductUpdate(updatedProduct) {
            const updatedProps = updatedDiff(
                clone(this.baseProduct),
                clone(updatedProduct)
            )

            this.products = this.products
                .map(this.resolveModGroups(updatedProduct))
                .map(this.resolveTags(updatedProduct))
                .map(product => {
                    return Object.assign(product, updatedProps, {
                        taxes: product.taxes,
                    })
                })
                .map(this.resolveTaxes(updatedProduct))

            this.$emit('input', this.products)
        },

        resolveModGroups: updatedProduct => originalProduct => {
            const originalGroups = originalProduct.modGroups
            const newGroups = updatedProduct.modGroups
            const updatedGroups = removeDups(originalGroups.concat(newGroups), 'title')

            return {
                ...originalProduct,
                modGroups: updatedGroups,
            }
        },

        resolveTags: updatedProduct => originalProduct => {
            const originalTags = originalProduct.inventoryTags
            const updatedTags = updatedProduct.inventoryTags
            const tags = removeDups(originalTags.concat(updatedTags), 'tagDescription')

            return Object.assign(originalProduct, {
                inventoryTags: tags,
            })
        },

        resolveTaxes: updatedProduct => originalProduct => {
            const updatedTaxes = Array.isArray(updatedProduct.taxes) ?
                updatedProduct.taxes : Object.values(updatedProduct.taxes)

            const originalTaxes = Array.isArray(originalProduct.taxes) ?
                originalProduct.taxes : Object.values(originalProduct.taxes)

            const newTaxes = updatedTaxes.filter(tax => {
                return !originalTaxes.some(el => el.Description === tax.Description)
            })

            const taxes = originalTaxes.map(tax => {
                const updatedTax = updatedTaxes.find(el => el.Description === tax.Description)

                if (updatedTax) {
                    return Object.assign(tax, {
                        Amount: updatedTax.Amount == 0 ? tax.Amount : updatedTax.Amount,
                    })
                }

                return tax
            })

            return Object.assign(originalProduct, {
                taxes,
            })
        },
    },
}
</script>
