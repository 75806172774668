var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.groups, function (x) {
      return _c(
        "draggable",
        {
          attrs: {
            list: _vm.items[x],
            options: {
              handle: [".drag-handle"],
              animation: 150,
              group: x,
            },
            "empty-insert-threshold": 50,
            swapThreshold: 10,
          },
          on: {
            change: _vm.onDragEnd,
            start: function ($event) {
              return _vm.onDragStart(x)
            },
          },
        },
        [
          !_vm.items[x] || _vm.items[x].length === 0
            ? _c(
                "div",
                {
                  class: {
                    list__placeholder: true,
                    "list__placeholder-active": _vm.isDragInProgress === x,
                  },
                },
                [_vm._v(" Drop " + _vm._s(x) + " here ")]
              )
            : _vm._l(_vm.items[x], function (item, key) {
                return _c(
                  "div",
                  { key: key },
                  [_c("item-wrapper", { attrs: { item: item } })],
                  1
                )
              }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }