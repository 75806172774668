var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "line-item",
    {
      class: {
        "line-item--active": _vm.active,
        "line-item--pending-delete": _vm.pendingDelete,
      },
      attrs: { dark: true, id: _vm.item.id },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row no-gutters align-items-center justify-content-between height-xs--9 folder__item",
          class: {
            "folder__item--editing": _vm.editMode,
            "folder__item--editing": !_vm.item.actionBindings.onClick,
          },
          on: {
            click: function ($event) {
              _vm.item.actionBindings.onClick
                ? _vm.item.actionBindings.onClick(_vm.item)
                : true
            },
          },
        },
        [
          _c("div", {
            class: ["folder__item-status", _vm.item.status],
            attrs: { title: _vm.resolveItemTooltip(_vm.item.status) },
          }),
          _c(
            "div",
            {
              staticClass: "folder__item-handle col-1",
              class: { "folder__item-handle--active": _vm.editMode },
            },
            [
              _vm.editMode
                ? _c("div", { staticClass: "drag-handle icon icon--handle" })
                : _vm._e(),
            ]
          ),
          _vm.item.actionBindings.onDelete
            ? _c("div", {
                staticClass: "folder__item-remove icon icon--remove",
                on: {
                  click: function ($event) {
                    return _vm.item.actionBindings.onDelete(_vm.item)
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "col folder__item__title" }, [
            _vm.struct && _vm.struct.title
              ? _c("div", [
                  _vm._v(_vm._s(_vm.struct.title.value(_vm.item.value))),
                ])
              : _vm._e(),
          ]),
          _vm.struct
            ? _c("div", { staticClass: "col-6" }, [
                _c(
                  "div",
                  { staticClass: "row no-gutters align-items-center" },
                  _vm._l(_vm.struct, function (prop, key) {
                    return key !== "title"
                      ? _c(
                          "div",
                          { key: key, staticClass: "data-col col" },
                          [
                            typeof prop === "string"
                              ? [_vm._v(_vm._s(_vm.item.value[key]))]
                              : [_vm._v(_vm._s(prop.value(_vm.item.value)))],
                          ],
                          2
                        )
                      : _vm._e()
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }