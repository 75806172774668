<template>
    <div>
        <line-item class="height-xs--7">
            <div class="subcategory__line row no-gutters height-xs--inherit align-items-center" @click="toggleExpanded">
                <div class="subcategory__head col">
                    <div class="subcategory__icon" :class="{'subcategory__icon--active': !expanded}"></div>
                    <div class="subcategory__title">{{ item.title }}</div>

                    <template v-if='editMode'>
                        <div
                            class="drag-handle subcategory__sort icon icon--handle subcategory__action"
                            @click.stop.prevent
                        />

                        <div
                            v-if='item.actionBindings.onClick'
                            class="subcategory__edit subcategory__action"
                            @click.stop="item.actionBindings.onClick(item)"
                        />

                        <div
                            v-if='item.actionBindings.onDelete'
                            class="subcategory__delete subcategory__action"
                            @click.stop="item.actionBindings.onDelete(item)"
                        />
                    </template>
                </div>
            </div>
        </line-item>
        <div style='margin-left: 30px; padding-right: 30px;' v-if="expanded">
            <list :items='item.children' :parent='item' />
        </div>
    </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import { ItemType } from 'components/explorer_new/itemType';
import lineItem from 'components/line_item';

export default {
    name: 'subCategory',
    props: {
        item: Object
    },
    components: {
        list: () => import('components/explorer_new/list'),
        lineItem,
    },
    computed: {
        ...mapGetters("ExplorerStore", ["struct", "editMode", "isSelected", "isExpanded"]),
        expanded() {
            return this.isExpanded(ItemType.Subcategory, this.item.id);
        }
    },
    methods: {
        ...mapMutations("ExplorerStore", ["SET_EXPANDED"]),
        toggleExpanded() {
            this.SET_EXPANDED({
                type: ItemType.Subcategory,
                id: this.item.id,
                isExpanded: !this.expanded
            });
        }
    }
};
</script>

<style lang='scss'>
@import '~scss/variables';
@import '~scss/mixins';

.subcategory {
    &__line {
        background-color: #fff;
        cursor: pointer;
    }

    &__action {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: spacing(xs);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    }

    &__sort {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__edit {
        background-image: url('~img/edit_logo.svg');
    }

    &__delete {
        background-image: url('~img/delete.svg');
        background-size: 15px;
    }

    &__head {
        margin-left: 30px;
        display: flex;
        font-size: fz(sm);
    }

    &__icon {
        @include background-image('', 'subfolder_open.svg', '../../img/');
        background-size: contain;
        width: spacing(md);
        height: spacing(md);
        margin-left: spacing(sm);

        &--active {
            @include background-image('', 'subfolder_closed.svg', '../../img/');
        }
    }

    &__title {
        margin-left: spacing(xs);
        margin-top: 3px;
    }

    &__children {
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;
        &--inner {
            min-height: 10px;
        }
    }

    &__child {
        transition: $transition_short;

        &--ghost {
            border-left: 5px solid $brand_color;
        }
    }

    &--active {
        .subcategory__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    
}
</style>
