<template>
    <div v-if="productCategories.length">
        <context-title
            :passedActions="titleBarActions"
            :spaceBetween="false"
            title="Products"
        />

        <span v-if="editMode" class="edit-bar" />
        <iexplorer-header
            v-if="canEdit"
            logo="add"
            @click.native="enableCategoryModal('new')"
            >Add new category</iexplorer-header
        >

        <list :items="listItems" />

        <editing-modal v-if="modalActive">
            <editing-modal-header
                :items="modalItems"
                :cancelAction="reset"
                :saveAction="handleModalSave"
                displayProp="inventoryItemName"
            />

            <div class="modal-message">
                None of the following fields will update the selected products
                unless the fields are explicitly changed.
            </div>

            <multi-product-form
                v-if="modalItems.length"
                :value="modalItems"
                :modSets="modSets"
                :allProducts="products"
                :productCategories="productCategories"
                :store="activeStore"
                :integrated="integrated"
                @input="editedModalItems = arguments[0]"
            />
        </editing-modal>

        <editing-modal v-if="categoryModalType" size="sm">
            <template v-if="categoryModalType === 'new'">
                <alert-dialog
                    :actions="addCategoryActions"
                    class="category-modal"
                >
                    <form-input
                        v-model="newCategoryName"
                        :bare="true"
                        class="category-modal__input"
                        placeholder="New category name"
                        autofocus
                    />
                </alert-dialog>
            </template>

            <template v-else>
                <alert-dialog
                    :actions="editCategoryActions"
                    class="category-modal"
                >
                    <form-input
                        v-model="newCategoryName"
                        :bare="true"
                        class="category-modal__input"
                        placeholder="Category name"
                        autofocus
                    />
                </alert-dialog>
            </template>
        </editing-modal>
        <v-dialog v-model="subcategoryModal.isVisible" max-width="640">
            <v-card>
                <v-card-title class="text-h5"> {{this.subcategoryModal.title}} </v-card-title>

                <v-card-text>
                    <v-text-field
                        label="Subcategory name"
                        v-model="subcategoryModal.name"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="#93BD20"
                        text
                        @click="saveSubcategory()"
                    >
                        Save
                    </v-btn>

                    <v-btn
                        color="#93BD20"
                        text
                        @click="subcategoryModal.isVisible = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import clone from "clone";
import {
    getDateRange,
    parseCost,
    mapActionButtons,
    dateStringToTime,
} from "helpers";
import {
    resolveProductModSets,
    platformStringForProduct,
} from "helpers/products";
import bus from "../store/action_bus.js";
import iexplorerHeader from "../components/explorer/iexplorer_header.vue";
import iexplorer from "../components/explorer/iexplorer.vue";
import contextTitle from "../components/context_title.vue";
import editingModal from "../components/editing_modal.vue";
import editingModalHeader from "../components/editing_modal_header.vue";
import MultiProductForm from "../components/MultiProductForm";
import alertDialog from "../components/alert_dialog.vue";
import formInput from "../components/cleverly/FormInput.vue";
import AccessMixin from "mixins/access_helper.js";
import list from "components/explorer_new/list";
import {
    convertStoreProductsToExplorerItems,
    hasNonEmptyChildren,
} from "components/explorer_new/store_products_converter";
import { ItemType } from "components/explorer_new/itemType";

export default {
    name: "StoreProducts",
    mixins: [AccessMixin],
    data() {
        return {
            changedItems: [],
            categoriesSorted: false,
            productStruct: {
                title: {
                    value: (product) => product.inventoryItemName,
                },
                cost: {
                    display: "Price",
                    value: ({ costDisplay }) => this.parseCost(costDisplay),
                },
                time: {
                    display: "Time",
                    value: ({
                        startTimeLocal,
                        endTimeLocal,
                        inventoryItemID,
                        timeRanges,
                    }) => {
                        if (
                            timeRanges === undefined ||
                            timeRanges == null ||
                            timeRanges.length == 0
                        )
                            return this.getDateRange(
                                dateStringToTime(startTimeLocal),
                                dateStringToTime(endTimeLocal)
                            );
                        if (timeRanges.length != 7) return "Custom";
                        const match = timeRanges[0];
                        for (var range of timeRanges) {
                            if (
                                range.startTimeLocal != match.startTimeLocal ||
                                range.endTimeLocal != match.endTimeLocal
                            )
                                return "Custom";
                        }
                        return this.getDateRange(
                            dateStringToTime(match.startTimeLocal),
                            dateStringToTime(match.endTimeLocal)
                        );
                    },
                },
                posID: {
                    display: "POS ID",
                    value: (product) => {
                        if (
                            product.inventoryItemSubs != undefined &&
                            product.inventoryItemSubs.length > 1
                        ) {
                            return "Multi";
                        }
                        return product.pos_productID || "N/A";
                    },
                },
                grabID: {
                    display: "Grab ID",
                    value: (product) => product.id || "N/A",
                },
                platforms: {
                    display: "Platforms",
                    value: (product) => platformStringForProduct(product),
                },
            },
            pendingDelete: [],
            editedModalItems: [],
            editMode: false,
            modalActive: false,
            modalItems: [],
            newCategoryName: "",
            editedCategory: false,
            categoryModalType: false,
            draggedItem: false,
            addCategoryActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.closeCategoryModal();
                    },
                },
                {
                    type: "proceed",
                    display: "Add",
                    run: () => {
                        this.saveProductCategory(this.newCategoryName);
                        this.closeCategoryModal();

                        this.toast("Saving");
                    },
                },
            ],
            editCategoryActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => {
                        this.closeCategoryModal();
                    },
                },
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        if (
                            !this.newCategoryName ||
                            this.newCategoryName === ""
                        ) {
                            return this.toast(
                                "Category must have name",
                                "error"
                            );
                        }

                        this.updateProductCategory({
                            inventoryTitleDescription: this.newCategoryName,
                            inventoryTitleID:
                                this.editedCategory.id,
                        }).then(() => {
                            this.getActiveStore(this.$route.params.store);
                        });

                        this.editedCategory = false;

                        this.closeCategoryModal();

                        this.toast("Saving");
                    },
                },
            ],
            subcategoryModal: {
                isVisible: false,
                name: null,
                id: null,
                item: null,
                title: "Add subcategory"
            },
            listItems: {},
        };
    },
    computed: {
        activeItems() {
            return this.selectedItemIds(ItemType.Item);
        },
        titleBarActions() {
            let busActions = mapActionButtons({ bus, vm: this });

            if (this.canEdit) {
                busActions.push({
                    type: "add-new",
                    display: "New",
                    run: this.addNewProduct,
                });
            } else {
                let index = busActions.findIndex((el) => el.type == "edit");
                if (index != -1) busActions.splice(index, 1);
            }

            return busActions;
        },
        children() {
            return this.productCategories
                .filter(
                    (category) =>
                        category.inventoryTitleDescription !== "SHOW ALL"
                )
                .map(this.assignCategoryHandlers)
                .map((category, i) => ({
                    ...category,
                    id: category.inventoryTitleID,
                    originalSortOrder:
                        category.originalSortOrder !== undefined
                            ? category.originalSortOrder
                            : i,
                    children: category.children
                        .map((el) => ({
                            ...el,
                            status: !el.inventoryItemAvailable
                                ? "disabled"
                                : "",
                        }))
                        .map((x) =>
                            x.children
                                ? {
                                    ...this.assignSubCategoryHandlers(x),
                                    children: x.children.map(
                                        this.assignHandlers
                                    ),
                                }
                                : this.assignHandlers(x)
                        ),
                }));
        },
        products() {
            return this.children.reduce((sum, next) => {
                return sum.concat(next.children);
            }, []);
        },
        actionBindings() {
            return {
                [ItemType.Folder]: {
                    onClick: this.handleCategoryEdit,
                    onDelete: this.handleCategoryDelete,
                    onAddSubcategory: this.handleSubCategoryAdd,
                },
                [ItemType.Subcategory]: {
                    onClick: this.handleSubCategoryEdit,
                    onDelete: this.handleSubCategoryDelete,
                },
                [ItemType.Item]: {
                    onClick: this.toggleItem,
                },
            };
        },
        ...mapState({
            editingModalEnabled: (state) => state.editingModalEnabled,
            editableItems: (state) => state.editableItems,
            activeStore: (state) => state.activeStore,
        }),
        ...mapGetters([
            "productCategories",
            "modSets",
            "timeOffset",
            "pos",
            "integrated",
            "inventoryItemIDsWithDayPartingEnabled",
        ]),
        ...mapGetters("ExplorerStore", [
            "pendingChanges",
            "hasPendingChanges",
            "selectedItemIds",
        ]),
    },
    methods: {
        handleCategoryEdit(item) {
            this.editedCategory = item;
            this.newCategoryName = item.title;

            this.enableCategoryModal("edit");
        },

        handleCategoryDelete(item) {
            if (hasNonEmptyChildren(item)) {
                return this.toast(
                    "You must move or delete all items in this category before you can delete it."
                );
            }

            this.deleteProductCategory(item.id);

            this.toast("Deleting");

            bus.$emit("collapseAllItems");
            this.reset();
        },

        handleSubCategoryEdit(item) {
            this.subcategoryModal.id = item.id;
            this.subcategoryModal.item = item;
            this.subcategoryModal.name = item.title;
            this.subcategoryModal.isVisible = true;
            this.subcategoryModal.title = "Edit Subcategory";
        },
        handleSubCategoryDelete(item) {
            if (hasNonEmptyChildren(item)) {
                return this.toast(
                    "You must move or delete all items in this subcategory before you can delete it."
                );
            }

            // delete sub category
            this.toast("Deleting");
            this.deleteSubCategory({
                storeWaypointID: this.$route.params.store,
                id: item.id,
            }).then(() => {
                bus.$emit("collapseAllItems");
                this.reset();
            });
        },
        handleSubCategoryAdd(item) {
            this.subcategoryModal.titleID = item.id;
            this.subcategoryModal.id = null;
            this.subcategoryModal.item = item;
            this.subcategoryModal.name = null;
            this.subcategoryModal.isVisible = true;
            this.subcategoryModal.title = "Add Subcategory"

        },
        saveSubcategory() {
            // TODO: save subcategory to server
            if (this.subcategoryModal.id) {
                // EDIT
                this.toast(`Editing subcategory ${this.subcategoryModal.name}`);
                const payload = {
                    inventorySubTitleID: this.subcategoryModal.id,
                    Name: this.subcategoryModal.name,
                };
                this.editSubCategory({
                    storeWaypointID: this.$route.params.store,
                    payload,
                });
            } else {
                // Create new
                this.toast(
                    `Creating subcategory ${this.subcategoryModal.name}`
                );
                const payload = {
                    InventoryTitleID: this.subcategoryModal.titleID,
                    Name: this.subcategoryModal.name,
                };
                this.createSubCategory({
                    storeWaypointID: this.$route.params.store,
                    payload,
                });
            }
            this.subcategoryModal.isVisible = false;
        },
        toggleItem(item) {
            const id = item.id;
            if (this.editMode) {
                this.toggleSelected({
                    type: ItemType.Item,
                    id,
                });
            } else if (this.canView) {
                this.$router.push({
                    name: "store-product-edit",
                    params: { product: id },
                });
            }
        },
        initModalItems() {
            const modalItems = this.products.filter(({ id }) => {
                return this.activeItems.find((item) => item === id);
            });

            if (modalItems.length) {
                this.modalItems = modalItems;
                this.modalActive = true;
            }
        },
        handleProductsUpdate(products) {
            this.editedModalItems = products;
        },
        handleModalSave() {
            const rawProducts = clone(this.editedModalItems);

            const products = rawProducts.map((product) => {
                const filteredOptions =
                    product.inventoryMainOptionChoice.Options.filter((el) => {
                        return !el.bIsIncludedInModSet;
                    }).map((el, i) => {
                        return Object.assign(el, {
                            optionGroupDisplayOrder: String(i + 1),
                        });
                    });

                return Object.assign(product, {
                    inventoryMainOptionChoice: {
                        Choices: [],
                        Options: filteredOptions,
                    },
                });
            });

            const payload =
                this.pos === "grab"
                    ? products
                    : { items: products, pos: this.pos };

            this.saveProducts(payload).then(() => {
                products.forEach((product) => {
                    const hasModSets = product.modSets.length;

                    if (hasModSets) {
                        const modSets = resolveProductModSets(
                            product,
                            product.inventoryMainOptionChoice.Options
                        );

                        if (modSets) {
                            this.saveModSets(modSets);
                        }
                    }
                });
            });

            this.reset();
        },
        save() {
            const notify = () => {
                this.toast("Saved!", "success");
            };

            // const updatedParents = [];

            // const traverse = (children) => {
            //     for (const itemType of [
            //         ItemType.Folder,
            //         ItemType.Subcategory,
            //     ]) {
            //         for (const item of children[itemType] || []) {
            //             if (this.pendingChanges[itemType][item.id]) {
            //                 updatedParents.push(item);
            //             }
            //         }
            //     }
            // };

            const result = []
            for (const [index, category] of this.listItems[ItemType.Folder].entries()) {
                result.push({
                    inventoryTitleID: category.id,
                    inventoryTitleOrder: index,
                    items: category.children[ItemType.Item].map((x, i) => {
                        return {
                            inventoryItemID: x.id,
                            inventoryOrder: i
                        }
                    }),
                    subcategories: category.children[ItemType.Subcategory].map((x, i) => {
                        return {
                            inventorySubTitleID: x.id,
                            inventorySubTitleOrder: i,
                            items: x.children[ItemType.Item].map((y, k) => {
                                return {
                                    inventoryItemID: y.id,
                                    inventoryOrder: k
                                }
                            }),
                        }
                    })
                })
            }

            // traverse(this.listItems);

            // const updatedItems = {
            //     [ItemType.Folder]: [],
            //     [ItemType.Subcategory]: [],
            //     [ItemType.Item]: [],
            // };

            // if (this.pendingChanges.hasRootSortUpdate) {
            //     updatedItems[ItemType.Folder].push(
            //         ...this.listItems[ItemType.Folder]
            //             .filter((x) => x.id != "0")
            //             .map((x, i) => ({
            //                 type: ItemType.Folder,
            //                 parentId: null,
            //                 parentType: null,
            //                 id: x.id,
            //                 orderNumber: i,
            //             }))
            //     );
            // }

            // for (const parent of updatedParents) {
            //     for (const [childType, children] of Object.entries(
            //         parent.children
            //     )) {
            //         updatedItems[childType].push(
            //             ...children.map((x, i) => ({
            //                 type: childType,
            //                 parentId: parent.id,
            //                 parentType: parent.type,
            //                 id: x.id,
            //                 orderNumber: i,
            //             }))
            //         );
            //     }
            // }

            // const categoriesToSort = updatedItems[ItemType.Folder].map(
            //     (x, i) => ({
            //         inventoryTitleID: x.id,
            //         inventoryTitleOrder: String(i),
            //     })
            // );

            // const subCategoriesToSort = updatedItems[ItemType.Subcategory].map(
            //     (x, i) => ({
            //         inventoryTitleID: x.parentId,
            //         inventorySubTitleID: x.id,
            //         inventorySubTitleOrder: String(i),
            //     })
            // );

            // // TODO remove console.error once implemented
            // console.error("Subcategories to sort: ", subCategoriesToSort);

            // const itemsToSort = updatedItems[ItemType.Item].map((x, i) =>
            //     x.parentType !== ItemType.Folder
            //         ? {
            //             inventoryItemID: x.id,
            //             inventoryTitleID: x.parentId,
            //             inventoryOrder: String(i),
            //         }
            //         : {
            //             inventoryItemID: x.id,
            //             inventorySubTitleID: x.parentId,
            //             inventoryOrder: String(i),
            //         }
            // );

            Promise.all([
                //this.sortCategories(categoriesToSort),
                //this.sortProducts(itemsToSort),
                this.sortEverything(result)
                // TODO this.sortSubcategories(subCategoriesToSort)
            ]).then(notify);

            this.reset();
        },
        reset() {
            this.modalActive = false;
            this.pendingDelete = [];
            this.editMode = false;
            this.resetExplorerStore();
            this.resetCategorySortOrder();
        },
        resetCategorySortOrder() {
            this.receiveProductCategories(this.productCategories);
        },
        closeCategoryModal() {
            this.newCategoryName = "";
            this.categoryModalType = false;
        },
        enableCategoryModal(type) {
            this.categoryModalType = type;
        },
        resolvePageTitle() {
            if (this.productCategories.length) {
                document.title = `${this.activeStore.storeName} - Menu`;
            }
        },
        addNewProduct() {
            if (this.pos === "grab") {
                this.$router.push({
                    name: "store-product-add-new",
                    param: this.$route.params,
                });
            } else {
                this.$router.push({
                    name: "store-product-add-database",
                    param: this.$route.params,
                });
            }
        },
        ...mapActions({
            remove: "deleteProducts",
            saveProductCategory: "saveProductCategory",
            updateProductCategory: "updateProductCategory",
            deleteProductCategory: "deleteProductCategory",
            sortCategories: "sortCategories",
            sortProducts: "sortProducts",
            saveProducts: "saveProducts",
            saveModSets: "saveModSets",
            getActiveStore: "getActiveStore",
            createSubCategory: "createSubCategory",
            deleteSubCategory: "deleteSubCategory",
            editSubCategory: "editSubCategory",
            sortEverything: "sortEverything"
        }),
        ...mapActions("ExplorerStore", [
            "initializeWithCategories",
            "toggleSelected",
        ]),
        ...mapActions("ExplorerStore", {
            resetExplorerStore: "reset",
        }),
        ...mapMutations({
            receiveProductCategories: "RECIEVE_PRODUCT_CATEGORIES",
            initStoreStruct: "ExplorerStore/SET_STRUCT",
            setEditMode: "ExplorerStore/SET_EDIT_MODE",
            setPendingDelete: "ExplorerStore/SET_PENDING_DELETE",
            setExpandedForTypes: "ExplorerStore/SET_EXPANDED_FOR_TYPES",
        }),
        getDateRange,
        parseCost,
    },
    components: {
        MultiProductForm,
        iexplorerHeader,
        iexplorer,
        contextTitle,
        editingModal,
        editingModalHeader,
        alertDialog,
        formInput,
        list,
    },
    watch: {
        productCategories(val) {
            this.resolvePageTitle();
            this.initStoreStruct(this.productStruct);
            this.listItems = convertStoreProductsToExplorerItems(
                val,
                this.actionBindings
            );
        },
        editMode(val) {
            this.setEditMode(val);
        },
        pendingDelete(val) {
            this.setPendingDelete({ type: ItemType.Item, ids: val });
        },
    },
    created() {
        this.resolvePageTitle();
        bus.$on("collapseAllItems", () => {
            this.setExpandedForTypes({
                types: [ItemType.Folder, ItemType.Subcategory],
                isExpanded: false,
            });
        });
        bus.$on("expandAllItems", () => {
            this.setExpandedForTypes({
                types: [ItemType.Folder, ItemType.Subcategory],
                isExpanded: true,
            });
        });
    },
    mounted() {
        if (this.productCategories) {
            this.initStoreStruct(this.productStruct);
            this.listItems = convertStoreProductsToExplorerItems(
                this.productCategories,
                this.actionBindings
            );
        }
    },
};
</script>

<style lang="scss" scoped>
.iexplorer__header-inner.line-item {
    cursor: pointer;
    padding-left: 30px;
}

.category-modal {
    &__input {
        & > input {
            width: 100%;
        }
    }
}

.modal-message {
    margin-bottom: 18px;
    margin-left: 18px;
}
</style>
